<template>
  <router-link :to="url" class="go-back" :class="{light}">
    <img v-if="light" src="@/assets/img/solana/arrow-left-light.svg" alt="arrow">
    <img v-else src="@/assets/img/solana/arrow-left.png" alt="arrow">
    <span> {{ $t(label) }} </span>
  </router-link>
</template>

<script>
export default {
  name: 'GoBack',
  props: {
    label: String,
    url: String,
    light: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors';

.go-back{
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #B9BFF4;
  text-decoration: none;
  width: fit-content;
  img{
    margin-right: 1.2rem;
    height: 2.2rem;
  }
  &.light{
    color: #3B7982;
  }
}
</style>
