<template>
  <v-dialog
    v-model="dialog"
    content-class="solana-modal add-liquidity"
    @click:outside="close"
    eager
    overlay-opacity="1"
  >
    <div class="solana-modal-close" @click="close"></div>

    <div class="add-liquidity-body">
      <div class="add-liquidity-left">
        <div class="add-liquidity-left-title solana-title">
          {{ $t('solana.add_liquidity_modal.input_details') }}
        </div>
        <div class="add-liquidity-subtitle light-text2">
          {{ $t('solana.add_liquidity_modal.allocate_your_stake') }}
        </div>

        <v-text-field
          class="solana-text-field mb-field"
          :placeholder="$t('solana.add_liquidity_modal.enter_amount')"
          v-model="amount"
          suffix="SHT"
        ></v-text-field>

        <v-text-field
          class="solana-text-field mb-field"
          :placeholder="$t('solana.add_liquidity_modal.enter_your_referral')"
        />

        <v-text-field
          class="solana-text-field mb-field"
          :placeholder="$t('solana.add_liquidity_modal.enter_number_of_days')"
          :hint="`67 ${$t('solana.add_liquidity_modal.days_left')}`"
          persistent-hint
        >
          <template v-slot:append>
            <s-select
              :options="times"
              v-model="time"
            />
          </template>
        </v-text-field>

        <div class="add-liquidity-note light-text2">
          {{ $t('solana.add_liquidity_modal.note.part1') }}
          <span class="light-text">SHT</span>
          {{ $t('solana.add_liquidity_modal.note.part2') }}
          <span class="light-text">7.96.</span>
          {{ $t('solana.add_liquidity_modal.note.part3') }}
        </div>
        <div class="d-flex justify-end mt-32">
          <Button class="no-shadow btn-solana btn-solana-dark">
            {{ $t('solana.buttons.approve') }}
          </Button>
        </div>
      </div>
      <div class="add-liquidity-right">
        <div class="add-liquidity-right-title light-text">
          {{ $t('solana.add_liquidity_modal.staking_summary') }}
        </div>
        <div class="add-liquidity-subtitle light-text2">
          {{ $t('solana.add_liquidity_modal.estimated_share') }}
        </div>

        <div class="add-liquidity-item d-flex align-center justify-space-between">
          <div class="light-text2">{{ $t('solana.add_liquidity_modal.staking_shares') }}</div>
          <div class="light-text d-flex align-center">
            <span>0.00 SHT</span>
            <v-tooltip top content-class="solana-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-bind="attrs"
                  v-on="on"
                  src="@/assets/img/solana/question.svg" alt="info"
                >
              </template>
              <span>Top tooltip</span>
            </v-tooltip>
          </div>
        </div>
        <div class="add-liquidity-item d-flex align-center justify-space-between">
          <div class="light-text2">{{ $t('solana.add_liquidity_modal.referral_bonus') }}</div>
          <div class="light-text d-flex align-center">
            <span>+5.00 %</span>
            <v-tooltip top content-class="solana-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-bind="attrs"
                  v-on="on"
                  src="@/assets/img/solana/question.svg" alt="info"
                >
              </template>
              <span>Top tooltip</span>
            </v-tooltip>
          </div>
        </div>
        <div class="add-liquidity-item d-flex align-center justify-space-between">
          <div class="light-text2">{{ $t('solana.add_liquidity_modal.length_bonus') }}</div>
          <div class="light-text d-flex align-center">
            <span>+0.00 %</span>
            <v-tooltip top content-class="solana-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-bind="attrs"
                  v-on="on"
                  src="@/assets/img/solana/question.svg" alt="info"
                >
              </template>
              <span>Top tooltip</span>
            </v-tooltip>
          </div>
        </div>

        <div class="add-liquidity-total d-flex align-center justify-space-between">
          <div class="light-text2">
            {{ $t('solana.add_liquidity_modal.total_estimated_rewards') }}
          </div>
          <div>
            <div class="light-text">0.00 {{ $t('solana.add_liquidity_modal.shares') }}</div>
            <div>0.0% {{ $t('solana.add_liquidity_modal.of_the_pool') }}</div>
          </div>
        </div>
        <div class="d-flex justify-start mt-32">
          <Button class="no-shadow btn-solana">{{ $t('solana.buttons.confirm') }}</Button>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
import Button from '../Button';
import SSelect from './SSelect';

export default {
  name: 'AddLiquidityModal',
  components: { SSelect, Button },
  props: {
    value: Boolean,
  },

  data() {
    return {
      dialog: false,
      amount: 123,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      times: ['days', 'months', 'years'],
      time: 'days',
    };
  },

  methods: {
    close() {
      this.$emit('input', false);
    },
  },

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/style/_colors';

.mb-field{
  margin-bottom: 1.4rem;
}
.mt-32{
  margin-top: 3.2rem;
}
.add-liquidity{
  width: 100%;
  max-width: 87.2rem;
  &-body{
    display: flex;
  }
  &-total{
    background: linear-gradient(180deg, #1F1E40 0%, #0E0D32 100%);
    border-radius: 1.2rem;
    padding: 1.4rem 1.6rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #636890;
    margin-top: 5rem;
    .light-text, .light-text2{
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    .light-text{
      font-weight: 500;
    }
  }
  &-item{
    height: 6rem;
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin-bottom: 3.6rem;
    .light-text{
      font-weight: 500;
      img{
        height: 2rem;
        margin-left: 0.8rem;
        cursor: pointer;
      }
    }
  }
  &-note{
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-top: 3.2rem;
  }
  &-left{
    width: 50%;
    padding-right: 1.2rem;
    box-sizing: border-box;
    &-title{
      text-align: center;
      margin-bottom: 0.7rem;
    }
  }
  &-subtitle{
    text-align: center;
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin-bottom: 1.6rem;
  }
  &-right{
    width: 50%;
    padding-left: 1.2rem;
    box-sizing: border-box;
    &-title{
      font-weight: 600;
      font-size: 2rem;
      line-height: 3.2rem;
      text-align: center;
      letter-spacing: 0.01em;
      margin-bottom: 0.7rem;
    }
  }
}
</style>
