<template>
  <v-dialog
    v-model="dialog"
    content-class="solana-modal transaction-status"
    @click:outside="close"
    eager
    overlay-opacity="1"
  >
    <div class="solana-modal-close" @click="close"></div>

    <div class="transaction-status-icon">
      <img src="@/assets/img/solana/check-circle.svg" alt="check-circle">
    </div>
    <div class="transaction-status-title solana-title">
      {{ $t('solana.transaction_submitted') }}
    </div>
    <div class="transaction-status-subtitle">{{ $t('solana.view_on_block_explorer') }}</div>
    <div class="d-flex justify-center">
      <Button class="no-shadow btn-solana" @click="close">{{ $t('solana.buttons.close') }}</Button>
    </div>

  </v-dialog>
</template>

<script>
import Button from '../Button';

export default {
  name: 'TransactionSubmittedModal',
  components: { Button },
  props: {
    value: Boolean,
  },

  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
  },

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/style/_colors';

.transaction-status{
  width: 42.4rem;
  text-align: center;
  &-icon{
    margin-top: 5.5rem;
  }
  &-title{
    margin-top: 2.4rem;
  }
  &-subtitle{
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
    color: $s-text-color2;
    margin-bottom: 3.2rem;
  }
  .btn-solana{
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
