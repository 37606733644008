<template>
  <div
    class="s-select"
    v-click-outside="onClickOutside"
    :class="{'s-select-small' : small, 's-select-simple' : !outlined, light}"
  >
    <div class="s-select-trigger light-text" @click="is_open = !is_open">
      <div class="s-select-trigger-custom" v-if="custom_trigger">
        <slot name="trigger" v-bind:item="value"></slot>
      </div>
      <div class="s-select-trigger-default" v-else>
        {{ val }}
      </div>
      <i
        v-if="trigger_icon"
        :class="`mdi ${is_open ? 'mdi-menu-up' : 'mdi-menu-down'} s-select-trigger-icon`"
      ></i>
    </div>

    <v-slide-y-transition>
      <div class="s-select-menu" v-show="is_open">
          <div
            class="s-select-item"
            :class="{active: JSON.stringify(option) === JSON.stringify(value)}"
            v-for="(option, j) of options"
            :key="j"
            @click="selectItem(option)"
          >
            <slot v-if="custom_option" name="option" v-bind:option="option"></slot>
            <span class="s-select-item-content" v-else> {{ option }} </span>

          </div>
      </div>
    </v-slide-y-transition>

  </div>
</template>

<script>
export default {
  name: 'SSelect',
  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: [Object, String, Number],
    },
    trigger_icon: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    value_field: {
      type: String,
    },
    custom_trigger: {
      type: Boolean,
      default: false,
    },
    custom_option: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_open: false,
    };
  },

  methods: {
    onClickOutside() {
      this.is_open = false;
    },
    selectItem(option) {
      this.$emit('input', option);
      this.is_open = false;
    },
  },

  computed: {
    val() {
      if (typeof this.value === 'object') {
        return this.value_field in this.value
          ? this.value[this.value_field] : this.value;
      }
      return this.value;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/style/functions';
@import '@/assets/style/_colors';

.s-select{
  position: relative;
  &-trigger{
    border: 0.5px solid #29284E;
    box-sizing: border-box;
    border-radius: 4.4rem;
    height: 4.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem;
    cursor: pointer;
    font-size: 1.8rem;
    user-select: none;
    &-icon{
      font-size: 2.2rem;
      margin-left: 1rem;
    }
    &-default{
      margin-left: 0.5rem;
    }
    &-custom{
      display: flex;
      align-items: center;
      height: 100%;
      img{
        height: 100%;
        margin-right: 1rem;
      }
    }
  }
  &-menu{
    background: #1B1A3B;
    border-radius: 2rem;
    padding: 0.5rem;
    position: absolute;
    top: calc(100% + 1rem);
    width: 100%;
    min-width: fit-content;
    z-index: 10;
  }
  &-item{
    border-radius: 2.2rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 175%;
    letter-spacing: 0.01em;
    color: $s-text-color;
    padding: 0.5rem;
    cursor: pointer;
    padding-right: 1rem !important;
    font-weight: normal;
    &-content{
      padding-left: 0.8rem;
      padding-right: 1rem;
    }
    &:not(:last-child){
      margin-bottom: 0.8rem;
    }
    &.active, &:hover{
      background: linear-gradient(180deg, #3C3A6C 0%, #292857 100%);
    }
    img{
      height: 100% !important;
      margin-right: 0.8rem;
    }
  }
  &-small &-trigger{
    height: 2.8rem;
    padding: 0.3rem;
    font-size: 1.4rem;
    &-icon{
      font-size: 1.8rem;
    }
  }
  &-small &-item{
    font-size: 1.2rem;
    padding: 0.3rem;
  }
  &-simple &-trigger{
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
  &.light &{
    &-trigger{
      border-color: #796DFF;
      color: #3D3D6D;
      &-icon{
        color: #796DFF;
      }
    }
    &-menu{
      background: #F7F7FE;
      box-shadow: 0 0 0 #BBBBBB;
    }
    &-item{
      color: #0C0C35;
      &.active, &:hover{
        background: linear-gradient(180deg, #8E8AF4 0%, #6E69FF 100%);
        color: white;
      }
    }
  }
}
</style>
