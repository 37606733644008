<template>
  <div class="solana-page">
    <div class="solana-page-top">
      <div class="solana-pool-switch">
        <s-select
          :options="items"
          v-model="type"
          :outlined="false"
        />

        <span class="solana-pool-switch-separator solana-title">/</span>

        <s-select
          v-model="pool"
          :options="cards"
          value_field="name"
          custom_trigger
          custom_option
          style="min-width: 127px"
        >
          <template v-slot:trigger="{item}">
            <img :src="require(`@/assets/img/solana/cards/${item.image}`)" :alt="item.name">
            <span>{{ item.name }}</span>
          </template>

          <template v-slot:option="{option}">
            <img :src="require(`@/assets/img/solana/cards/${option.image}`)" :alt="option.name">
            <span>{{ option.name }}</span>
          </template>
        </s-select>

        <span class="solana-pool-switch-separator solana-title">/</span>

        <div class="solana-title weight-normal">Reward #232dgr</div>

      </div>

      <v-btn
        class="default-btn pink-btn"
        rounded
        @click="add_liquidity = !add_liquidity"
      > {{$t('solana.add_liquidity')}} </v-btn>
    </div>

    <go-back url="/solana/pool" label="solana.back_to_pair"/>

    <reward-block/>

    <add-liquidity-modal v-model="add_liquidity" />
    <transaction-submitted-modal v-model="transaction_status"/>

  </div>
</template>

<script>
import SSelect from '@/components/Solana/SSelect';
import GoBack from '@/components/Solana/GoBack';
import RewardBlock from '@/components/Solana/RewardBlock';
import AddLiquidityModal from '@/components/Solana/AddLiquidityModal';
import TransactionSubmittedModal from '@/components/Solana/TransactionSubmittedModal';

export default {
  name: 'Reward',
  components: {
    TransactionSubmittedModal,
    AddLiquidityModal,
    RewardBlock,
    GoBack,
    SSelect,
  },

  data() {
    return {
      items: ['Single', 'Pair'],
      type: 'Single',
      add_liquidity: false,
      transaction_status: false,
      pool: {
        image: '1.png',
        name: 'SHT/ETH',
      },
      cards: [
        {
          image: '1.png',
          name: 'SHT/ETH',
        },
        {
          image: '2.png',
          name: 'SHT',
        },
        {
          image: '3.png',
          name: 'SHT',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
