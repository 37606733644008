<template>
  <section class="reward">
    <div class="reward-left">
      <div class="reward-header">
        <div>
          <div class="light-text2"> {{ $t('solana.tvl') }} </div>
          <div class="light-text">$ 157M</div>
        </div>
        <div>
          <div class="light-text2">{{ $t('solana.farming_apy') }}</div>
          <div class="light-text">240%</div>
        </div>

        <div class="reward-header-icon">
          <img src="@/assets/img/solana/eth-icon.svg" alt="eth">
        </div>
      </div>

      <div class="reward-left-title">
        {{ $t('solana.deposit_lp_token') }}
      </div>
      <div class="reward-status d-flex justify-center">
        <div class="reward-status-active">{{ $t('solana.status.active') }} </div>
      </div>

      <div class="reward-items">
        <div class="d-flex justify-space-between">
          <div class="light-text2">{{ $t('solana.total_pair_liquidity') }}</div>
          <div class="light-text">$ 157M</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="light-text2">{{ $t('solana.pair_tranding_volume') }}</div>
          <div class="light-text">$ 15M</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="light-text2">{{ $t('solana.total_rewards') }}</div>
          <div class="light-text">40.000 SHT</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="light-text2">{{ $t('solana.rewards_remaning') }}</div>
          <div class="light-text">28,312.22 SHT</div>
        </div>
      </div>

      <div class="reward-info">
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.status.active') }}</div>
          <div class="light-text">1d:12h:33m:12s</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.max_pool_size') }}</div>
          <div class="light-text">{{ $t('solana.unlimited') }}</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.rewards_type') }}</div>
          <div class="light-text">{{ $t('solana.single_unlocked') }}</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.pool_type') }}</div>
          <div class="light-text">{{ $t('solana.unlocked_staking') }}</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.start_date') }}</div>
          <div class="light-text">03-12-2021</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.end_date') }}</div>
          <div class="light-text">04-01-2022</div>
        </div>
      </div>

    </div>

    <div class="reward-right">
      <div class="reward-right-content">
        <div class="reward-right-title">{{ $t('solana.your_stake') }}</div>

        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.staked') }}</div>
            <div>SHT-ETH-V2</div>
          </div>
          <div>
            <div class="d-flex align-center">
              3,453.04
              <img src="@/assets/img/solana/eth-icon.svg" alt="eth">
            </div>
            <div class="d-flex align-center">
              3,453.04
              <img src="@/assets/img/solana/eth-icon.svg" alt="eth">
            </div>
          </div>
        </div>
        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.rewards') }}</div>
            <div>SHT</div>
          </div>
          <div>3,453.04</div>
        </div>
        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.available_to_claim') }}</div>
            <div>SHT</div>
          </div>
          <div> 3,453.04 </div>
        </div>
        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.claimed') }}</div>
            <div>SHT</div>
          </div>
          <div> 1,453.04 </div>
        </div>
        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.your_referral') }}</div>
            <div>0xew2...23we3</div>
          </div>
          <div class="reward-copy">
            <v-tooltip top content-class="solana-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  class="copy"
                  src="@/assets/img/solana/copy-icon.svg"
                  alt="copy"
                  v-bind="attrs"
                  v-on="on"
                  @click="copy"
                >
              </template>
              <span>{{ $t('solana.copy') }}</span>
            </v-tooltip>

          </div>
        </div>

        <div class="reward-right-grid">
          <div class="reward-right-column d-flex align-center justify-center text-center">
            <div class="light-text">{{ $t('solana.apply_for_rewards') }}</div>
            <div class="light-text2">SHT</div>
          </div>
          <div class="reward-right-column d-flex align-center justify-center text-center">
            <div class="light-text">{{ $t('solana.claim_rewards') }}</div>
            <div class="light-text2"> SHT {{ $t('solana.reward') }}</div>
          </div>
          <div class="reward-right-column d-flex align-center justify-center text-center">
            <div class="light-text">{{ $t('solana.withdraw_stake_only') }}</div>
            <div class="light-text2">{{ $t('solana.single') }} SHT</div>
          </div>
          <div class="reward-right-column d-flex align-center justify-center text-center">
            <div class="light-text">{{ $t('solana.exit_from_stake') }}</div>
            <div class="light-text2">{{ $t('solana.withdraw_and_claim') }}</div>
          </div>
        </div>
      </div>
    </div>

    <v-snackbar
      v-model="copy_status"
      right
      top
      class="solana-snackbar solana-snackbar-success"
    >
      {{ $t('solana.copied') }}
    </v-snackbar>

  </section>
</template>

<script>
export default {
  name: 'RewardBlock',
  data() {
    return {
      copy_status: false,
    };
  },
  methods: {
    copy() {
      this.copy_status = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors';
.copy{
  cursor: pointer;
  height: 2.6rem;
}
</style>
